<template>
  <div class="command-table">
    <div class="actions-row">
      <base-button type="THEMED" :isSmall="true" v-on:click="resolve()" title="Mark as Resolved" />
    </div>
    <table>
      <thead>
        <tr>
          <th>
            <CheckboxProperty v-model="checkbox.checkAll" />
          </th>
          <th>Source</th>
          <th>Message</th>
          <!-- <th>Information</th> -->
          <th>Triggers</th>
          <th>Moment</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="aggregate in aggregates"
          :key="aggregate._id"
          :class="{ marked: aggregate.marked }"
        >
          <td>
            <CheckboxProperty v-model="aggregate.selected" />
          </td>
          <td>
            <a @click="goToKuma(aggregate.source.monitor.id)">{{ aggregate.name }}</a>
          </td>
          <td class="name">{{ aggregate.last_message }}</td>
          <!-- <td><a @click="moreInfo(alert.id)">More Info</a></td> -->
          <td>
            {{ aggregate.triggers }}
          </td>
          <td>{{ $date(aggregate.updatedAt).fromNow() }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Utils from '@/utils';
import CheckboxProperty from '../Property/CheckboxProperty.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'AlertTable',
  components: {
    CheckboxProperty,
    BaseButton,
  },
  props: ['refresh'],
  watch: {
    aggregates: {
      handler() {
        this.checkButtons();
      },
      deep: true,
    },
    'checkbox.checkAll': {
      handler(val) {
        this.aggregates = this.aggregates.map((e) => {
          e.selected = val;
          return e;
        });
      },
    },
    '$route.query.view': {
      handler(v) {
        if (v) {
          this.markRow(v);
        }
      },
    },
  },
  data() {
    return {
      checkbox: {
        checkAll: false,
      },
      aggregates: [],
      buttons: {
        read: false,
        unread: false,
        resolve: false,
      },
    };
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    checkButtons() {
      this.buttons.read = this.aggregates.filter((e) => e.selected).some((e) => !e.muted);
      this.buttons.unread = this.aggregates.filter((e) => e.selected).some((e) => e.muted);
      this.buttons.resolve = this.aggregates.filter((e) => e.selected).length > 0;
    },
    async resolve() {
      const ids = this.aggregates.filter((e) => e.selected).map((e) => e._id);
      if (ids.length) {
        await Utils.fetch(
          '/api/v1/alerts/aggregate',
          {
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: ids,
            }),
          },
          this,
        )
          .then((res) => res.json())
          .then(() => this.getAlerts())
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.response);
          });
      }

      // this.getAlerts();
    },
    markRow(id) {
      this.aggregates = this.aggregates.map((e) => {
        e.marked = e._id === id;
        return e;
      });
    },
    goToKuma(id) {
      window.open(`https://uptime.dev.screencom.eu/dashboard/${id}`);
    },
    async getAlerts() {
      const response = await Utils.fetch('/api/v1/alerts/aggregate', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.aggregates = response.aggregates.map((e) => {
          e.selected = false;
          e.marked = false;
          return e;
        });
      } else {
        this.$noty.warning(response.message);
      }
      this.checkbox.checkAll = false;
    },
    moreInfo(id) {
      this.$router.push(`${this.$route.path}?view=${id}`);
    },
  },
  mounted() {
    this.getAlerts().then(() => {
      if (this.$route.query.view && this.$route.query.view !== '') {
        this.markRow(this.$route.query.view);
      }
    });

    this.$root.$on('alertChange', () => {
      this.getAlerts();
    });
  },
};
</script>

<style scoped lang="scss">
.command-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.command-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--accent-color);
    }

    &:hover {
      background: var(--accent-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }
}

td a {
  text-decoration: underline;
}

td .unread {
  color: red;
}

.actions-row {
  padding: 10px 0;

  a {
    padding: 4px 8px;
    border: 1px solid white;
    border-radius: 3px;
    margin-right: 5px;
    display: inline-block;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.3;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}

tr.marked {
  background: rgba(255, 255, 255, 0.1);
}
</style>
